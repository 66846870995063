import gql from 'graphql-tag'
import { TransactionTemplateFragment } from '../transactionTemplate'
import { SaleAddressFragment } from '../address' 
import { InventoryFragment } from '../inventory'
import { WorkflowFragment } from '../workflow'
import { TransactionItemFragment } from './transactionItem'
import { AdditionalDetailFragment } from '../transactionTemplate'

export const CrmInvoiceFragment = gql`
  fragment CrmInvoiceFragment on Invoice {
    id
    uuid

    transaction_number
    locale
    title
    created_by
    updated_by
    date_out
    due_date
    currency
    template {
      ...TransactionTemplateFragment
    }
    status # sent or unsent
    paid_status # paid, partially paid, or unpaid
    sent_at
    archived
    updated_at

    sale_address {
      ...SaleAddressFragment
    }

    sales_first_name
    sales_last_name
    sales_email
    sales_phone
    sales_image_url
    sales_contact_id
    sales_contact_kyc_verified

    shipped_street
    shipped_street2
    shipped_city
    shipped_state
    shipped_country
    shipped_zip

    state_for_nexus

    # Billing Address
    address_street
    address_street2
    address_city
    address_state
    address_country
    address_zip

    attn
    shipping_attn
    contact_id
    contact_kyc_verified
    first_name
    last_name
    billing_email
    billing_phone
    billing_image_url
    billing_honorific

    artist_contact_id
    artist_contact_kyc_verified
    artist_contact_first_name
    artist_contact_last_name
    artist_contact_email
    artist_contact_phone
    artist_contact_image_url

    shipped_contact_id
    shipped_contact_kyc_verified
    shipping_first_name
    shipping_last_name
    shipping_email
    shipping_phone
    shipping_image_url
    shipping_honorific

    shipping_fee
    tax_shipping
    tax_amount
    tax_mode
    tax_percentage
    tax_percentage_source
    tax_key

    # total for classic invoices
    total_price

    deposit_amount
    deposit_percentage

    additional_heading
    invoice_number_label
    date_label
    due_date_label
    billing_address_label
    shipping_address_label
    subtotal_label
    tax_label
    shipping_label
    total_label
    remaining_balance_label
    header_url
    header_alignment
    header_size
    footer_url
    footer_alignment
    item_caption
    show_currency_code
    show_currency_symbol
    show_payments
    show_discount_total
    hide_line_item_tax
    use_full_date
    show_due_date
    additional_details_own_page
    additional_details_font_size

    notes {
      id
      content
      created_by_id
      created_at
      updated_at
    }

    payment_method
    additional_details {
      ...AdditionalDetailFragment
    }
    transaction_items {
      ...TransactionItemFragment
    }
    payment_history {
      id
      amount
      description
      date
    }
    passcode
    download_link_color
    disable_artwork_item_price
    physical_sale
    qb_last_sync_date

    offer {
      clickthrough_count
    }

    workflow_stage {
      id
      name
      meta
    }

    workflow_id
    workflow_stage_id

    transaction_fee_type

    workflow {
      ...WorkflowFragment
    }

    tags {
      id
      tag
      source
    }
    voided_at

    tracking_link
    view_count
    
    __typename
  }
  ${TransactionTemplateFragment}
  ${SaleAddressFragment}
  ${AdditionalDetailFragment}
  ${TransactionItemFragment}
  ${InventoryFragment}
  ${WorkflowFragment}
`
