import { Invoice, CrmInvoice, InventoryCustomField } from '@@/types'
import {
  emptyInvoice,
  emptyLineItem,
  emptyContact,
  emptyAddress,
} from '@/models'
import { deepOmitNull, thumbnailUrl } from '@/utils'

export default (crmInvoice: CrmInvoice): Invoice => {
  const cleanInvoice: CrmInvoice = deepOmitNull(crmInvoice) as CrmInvoice

  const {
    id,
    uuid,
    transactionNumber,
    paidStatus,
    paidAmount,
    status,
    updatedAt,
    sentAt,
    dateOut,
    dueDate,

    saleAddress,

    shippedStreet,
    shippedStreet2,
    shippedCity,
    shippedState,
    shippedCountry,
    shippedZip,

    // billing address
    addressStreet,
    addressStreet2,
    addressCity,
    addressState,
    addressCountry,
    addressZip,

    contactId,
    attn,
    shippingAttn,
    firstName,
    lastName,
    billingMiddleName,
    billingPronouns,
    billingEmail,
    billingPhone,
    billingImageUrl,
    billingHonorific,

    artistContactId,
    artistContactFirstName,
    artistContactMiddleName,
    artistContactLastName,
    artistContactPronouns,
    artistContactEmail,
    artistContactPhone,
    artistContactImageUrl,

    shippedContactId,
    shippingFirstName,
    shippingMiddleName,
    shippingLastName,
    shippingPronouns,
    shippingEmail,
    shippingPhone,
    shippingImageUrl,
    shippingHonorific,

    shippingFee,
    taxShipping,

    // total for classic invoices
    totalPrice,

    taxAmount,
    taxPercentage,
    depositAmount,
    depositPercentage,

    notes,

    paymentMethod,
    additionalDetails,

    paymentHistory,

    transactionItems,

    additionalHeading,
    invoiceNumberLabel,
    dateLabel,
    dueDateLabel,
    billingAddressLabel,
    shippingAddressLabel,
    subtotalLabel,
    taxLabel,
    shippingLabel,
    totalLabel,
    remainingBalanceLabel,
    headerUrl,
    headerAlignment,
    headerSize,
    footerUrl,
    footerAlignment,
    itemCaption,
    showCurrencyCode,
    showCurrencySymbol,
    showPayments,
    showDiscountTotal,
    hideLineItemTax,
    showDueDate,
    useFullDate,

    templateId,
    template,
    qbLastSyncDate,
    offer,

    salesEmail,
    salesFirstName,
    salesMiddleName,
    salesLastName,
    salesPronouns,
    salesPhone,
    salesImageUrl,
    salesContactId,

    contactKycVerified,
    shippedContactKycVerified,
    artistContactKycVerified,
    salesContactKycVerified,

    tags,

    ...partialInvoice
  } = cleanInvoice
  return emptyInvoice({
    id: id?.toString(),

    shareId: uuid,
    invoiceNumber: transactionNumber,
    date: new Date(dateOut ?? new Date()),
    dueDate: dueDate ? new Date(dueDate) : undefined,

    status:
      paidStatus === 'paid'
        ? paidStatus
        : status === 'sent' || status === 'error sending'
        ? status
        : 'draft',
    paidStatus,
    paidAmount: (paidAmount ?? 0) / 100,
    savedAt: new Date(updatedAt ?? Date.now()),
    sentAt: sentAt ? new Date(sentAt) : undefined,

    saleAddress: emptyAddress({
      ...saleAddress,
    }),

    salesContact: emptyContact({
      id: salesContactId?.toString(),
      firstName: salesFirstName,
      middleName: salesMiddleName,
      lastName: salesLastName,
      pronouns: salesPronouns,
      email: salesEmail,
      phoneHome: salesPhone,
      imageUrl: salesImageUrl,
      kycVerified: salesContactKycVerified,
    }),

    customerContact: emptyContact({
      id: contactId?.toString(),
      attn,
      firstName,
      lastName,
      middleName: billingMiddleName,
      pronouns: billingPronouns,
      email: billingEmail,
      phoneHome: billingPhone,
      imageUrl: billingImageUrl,
      honorific: billingHonorific,
      kycVerified: contactKycVerified,
    }),

    artistContact: emptyContact({
      id: artistContactId?.toString(),
      firstName: artistContactFirstName,
      middleName: artistContactMiddleName,
      lastName: artistContactLastName,
      pronouns: artistContactPronouns,
      email: artistContactEmail,
      phoneHome: artistContactPhone,
      imageUrl: artistContactImageUrl,
      kycVerified: artistContactKycVerified,
    }),

    shippingContact: emptyContact({
      id: shippedContactId?.toString(),
      attn: shippingAttn,
      firstName: shippingFirstName,
      middleName: shippingMiddleName,
      lastName: shippingLastName,
      pronouns: shippingPronouns,
      email: shippingEmail,
      phoneHome: shippingPhone,
      imageUrl: shippingImageUrl,
      honorific: shippingHonorific,
      kycVerified: shippedContactKycVerified,
    }),

    billingAddress: emptyAddress({
      line1: addressStreet ?? '',
      line2: addressStreet2 ?? '',
      locality: addressCity ?? '',
      region: addressState ?? '',
      country: addressCountry ?? '',
      postalCode: addressZip ?? '',
    }),

    shippingAddress: emptyAddress({
      line1: shippedStreet ?? '',
      line2: shippedStreet2 ?? '',
      locality: shippedCity ?? '',
      region: shippedState ?? '',
      country: shippedCountry ?? '',
      postalCode: shippedZip ?? '',
    }),

    shipping:
      shippingFee && shippingFee > 0
        ? emptyLineItem({
            price: shippingFee / 100,
            taxAmount: taxShipping && taxAmount ? taxAmount : 0,
            isTaxed: taxShipping,
            dirty: false,
          })
        : null,

    taxShipping,

    // total for classic invoices
    totalPrice: (totalPrice ?? 0) / 100,

    paymentProviderId: paymentMethod,
    details: additionalDetails,

    taxAmount: (taxAmount ?? 0) / 100,
    taxPercentage: parseFloat(taxPercentage ?? '0'),
    depositPercent: parseFloat(depositPercentage ?? '0'),
    quickbooksLastSyncDate: qbLastSyncDate,
    depositAmount,

    lineItems: transactionItems?.map(
      ({
        inventory,
        discount,
        price,
        id,
        meta,
        discountPercent,
        ...partialItem
      }) =>
        emptyLineItem({
          ...partialItem,
          id: id?.toString(),
          price: (price ?? 0) / 100,
          // discount amounts in the core app are stored as strings
          ...(discount && {
            discountAmount: price >= 0 ? parseInt(discount) / 100 : 0,
          }),
          ...(discountPercent && {
            discountPercent: parseFloat(discountPercent),
          }),
          // set inventory image as fallback
          ...(!partialItem.imageUrl &&
            inventory && {
              imageUrl:
                (inventory?.url &&
                  thumbnailUrl(
                    inventory.url,
                    import.meta.env.VITE_THUMBNAIL_BASE_URL
                  )) ??
                inventory?.resourceUrl ??
                '',
            }),
          meta: meta
            ? meta.artworkCustomFields
              ? {
                  ...meta,
                  ...(
                    meta.artworkCustomFields as [InventoryCustomField]
                  ).reduce((obj, customField) => {
                    if (customField.organizationCustomField) {
                      obj[
                        customField.organizationCustomField.attributes.label.toLowerCase()
                      ] = customField.value
                    }
                    return obj
                  }, {} as Record<string, unknown>),
                }
              : meta
            : { ...inventory },
          inventory,
          taxAmount: price >= 0 ? price * parseFloat(taxPercentage ?? '0') : 0,
          dirty: false,
        })
    ),

    notes: notes?.map(
      ({ id, idPlaceholder, content, createdById, createdAt, updatedAt }) => ({
        id: id?.toString(),
        idPlaceholder,
        content,
        createdById,
        createdAt: new Date(createdAt),
        updatedAt,
        dirty: false,
      })
    ),

    payments: paymentHistory?.map(
      ({ id, idPlaceholder, description, date, amount }) => ({
        id: id?.toString(),
        idPlaceholder,
        amount: amount / 100,
        notes: description,
        date: new Date(date),
        dirty: false,
      })
    ),

    tags: tags?.map((tag) => ({ ...tag, dirty: false })),

    template: {
      id: templateId,
      ...template,
    },

    templateOverrides: {
      additionalHeading: additionalHeading ?? template?.additionalHeading,
      invoiceNumberLabel: invoiceNumberLabel ?? template?.invoiceNumberLabel,
      dateLabel: dateLabel ?? template?.dateLabel,
      dueDateLabel: dueDateLabel ?? template?.dueDateLabel,
      billingAddressLabel: billingAddressLabel ?? template?.billingAddressLabel,
      shippingAddressLabel:
        shippingAddressLabel ?? template?.shippingAddressLabel,
      subtotalLabel: subtotalLabel ?? template?.subtotalLabel,
      taxLabel: taxLabel ?? template?.taxLabel,
      shippingLabel: shippingLabel ?? template?.shippingLabel,
      totalLabel: totalLabel ?? template?.totalLabel,
      remainingBalanceLabel:
        remainingBalanceLabel ?? template?.remainingBalanceLabel,
      headerUrl: headerUrl ?? template?.headerUrl,
      headerAlignment: headerAlignment ?? template?.headerAlignment ?? 'left',
      headerSize: headerSize ?? template?.headerSize ?? 'medium',
      footerUrl: footerUrl ?? template?.footerUrl,
      footerAlignment: footerAlignment ?? template?.footerAlignment ?? 'left',
      itemCaption: itemCaption ?? template?.itemCaption,
      showCurrencyCode: showCurrencyCode ?? template?.showCurrencyCode ?? false,
      showCurrencySymbol:
        showCurrencySymbol ?? template?.showCurrencySymbol ?? false,
      showPayments: showPayments ?? template?.showPayments ?? false,
      showDiscountTotal:
        showDiscountTotal ?? template?.showDiscountTotal ?? false,
      hideLineItemTax: hideLineItemTax ?? template?.hideLineItemTax ?? false,
      showDueDate: showDueDate ?? template?.showDueDate ?? false,
      useFullDate: useFullDate ?? template?.useFullDate ?? false,
    },

    openCount: offer?.clickthroughCount ?? 0,
    ...partialInvoice,
  })
}
