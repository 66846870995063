---
# brand
project: Invoice
company: Arternal
title: ARTERNAL

# top level site navigation
nav:
  invoices:
    index: All Invoices
    draft: Drafts
    sent: Sent
    viewed: Viewed
    partiallyPaid: Partially Paid
    unpaid: Unpaid
    paid: Paid
    archived: Archived
    voided: Voided
  invoice:
    edit: Edit
    view: View
    send: Send
    payments: Payments
    sentHistory: Sent History
    notes: Notes
  templates:
    heading: Templates
    index: Templates
    edit: Edit
    details: Details
  settings:
    heading: Settings
    invoice: Invoice Settings
  arternal:
    heading: Arternal
    crm: Return to Dashboard
  signin: Sign in
  nexus: Economic Nexus
  posAddress: Point of Sale
  paymentDash: Stripe Dashboard

# used in combination with "<action>Model" keys
# intended to dry up "create template", "create invoice", etc.
models:
  template: Template
  invoice: Invoice
  preset: Preset

# used for prompts and alerts
messages:
  default: Default
  wait: Please wait...
  saving: Saving
  loading: Loading...
  choosePredefined: Choose a predefined template to create a custom template
  noInvoices: No results found
  noInvoicesFilter: No results found that match this filter
  noTemplates: You do not have any custom templates
  noAddress: No address provided
  noContact: No contact provided
  noContactName: No name provided
  confirmDeleteInvoice: DELETE this invoice? This cannot be undone.
  confirmArchiveInvoice: Are you sure you want to archive this invoice?
  confirmUnarchiveInvoice: Are you sure you want to unarchive this invoice?
  invoiceSavedAt: Invoice saved at {time}
  confirmNewPasscode: Are you sure you want to generate a new passcode?
  confirmNoPasscode: Are you sure you want to remove this passcode?
  alertCurrencyMismatch: Currency must be {currency}
  buyerTaxRate: Shipping address ({city}, {postalCode}) has a suggested tax rate of {taxRate}%
  noShippingAddress: Enter a shipping address to calculate the tax rate automatically.
  taxRateFailed: Could not determine a tax rate for the shipping address, please enter one manually below.
  invoiceEditsIsolated: Edits made here will only affect this invoice
  shareUrlCopied: Share link copied
  passcodeCopied: Passcode copied
  invoiceArchived: Invoice archived
  invoiceSent: Invoice sent
  sendFailed: Could not send, please try again
  invoiceUpdatedBy: Updated {date} by {name}
  multipleCurrenciesInInvoice: This invoice has multiple currencies
  templateDefault: Template set as default
  confirmTemplateDelete: Are you sure you want to delete this template?
  templateDeleted: Template deleted
  templateDuplicated: Template duplicated
  templateSaved: Template saved
  confirmLeaveUnsaved: Are you sure you want to leave with unsaved changes?
  setInstructionsAndTermsInSettings: You can set these additional details in your invoice settings
  pageNumber: Page {page}
  predefinedTemplateEdit: To start modifying this predefined template click
  chooseTemplate: Choose a custom template to create your invoice from
  chooseTemplateWithInventory: Choose a custom template for your <b>selected artworks ({numWorks})</b>.
  createInvoice: Creating invoice...
  createInvoiceError: Invoice could not be created, please try again
  noDetailPresets: This template does not have any details yet
  quickbooksTaxAgencyRequired:
    'QuickBooks requires a tax agency to be selected when adding tax to an invoice.
    The tax agency should match the region the items are shipping to.'
  lastQuickbooksSyncDate: 'Synced {date}'
  quickbooksHomeCurrencyWarning:
    'The currency for this invoice is {invoiceCurrency} while the QuickBooks currency is {quickbooksHomeCurrency}.
    This invoice will be entered in QuickBooks as {quickbooksHomeCurrency} without conversions and
    results in QuickBooks will be inaccurate.'
  quickbooksInvoiceSyncSuccessful: Invoice synced successfully
  quickbooksInvoiceSyncFailed: Could not sync invoice
  quickbooksNoBillingContact: No billing contact set on invoice
  quickbooksNoTaxAgency: No tax agency selected
  quickbooksSyncStarted: Syncing...
  quickbooksCustomTxnNumbers: 
    'Please ensure that you are using the old invoicing layout on Quickbooks, and that custom transaction numbers 
    are turned off in your Quickbooks settings. Not doing so may yield unpredictable sync results. Please contact 
    Quickbooks support with any questions you may have.'
  editContactSafe: Any adjustments made here will not be saved back to your contacts
  editUserSafe: Any adjustments made here will not be saved back to your associates
  filteredResults: Currently displaying filtered results
  noContacts: No contacts available
  confirmStageChange: Are you sure you want to switch workflow stages? Certain changes will be lost.
  classicInvoiceInfo: This invoice was created on the classic version of our invoice tool. You may still add payments and notes here.
  invoiceSuccessHeading: Payment Successful
  invoiceSuccessBody: Thank you for your payment
  invoicePaid: This invoice was paid for on
  invoicePaymentPending: Please allow up to 5 business days for funds to be transferred.
  invoicePaymentPendingHeading: Payment Processed and now Pending.
  noEmail: no email
  emailNotLinked: The link between your email provider has failed. Please relink your email account.
  emailNotLinkedWithAction: The link between your email provider has failed. To send invoices -
  ccFeeTitle: Credit Card Transaction Fee
  ccFeeHelpLabel: 'Charge an additional fee for credit card payments'
  ccDefaultFee: '{percent} + 30¢ of the total: {total} {currency}'
  ccFeePercentTotal: '{percent} of the total: {total} {currency} (edited)'
  ccFeePercentAmount: '{percent} calculated fee: {amount} {currency}'
  ccFeeEdited: (edited)
  label: Label
  rate: Rate
  stripeDashboard: View payments made through ARTERNAL Pay via the Stripe Dashboard.
  invoiceSaveFailed: Invoice could not be saved
  imageCropFailed: Failed to crop image
  kycVerified: Verified
  labelCreateFailed: Failed to create label
  labelExists: Label already exists
  excludeArtworkPrice: "Artwork prices excluded"
  invoiceLabelsUpdated: Invoice labels updated
  invoiceLabelsUpdateFailed: Invoice labels could not be updated
  invoiceVoided: Invoice voided
  invoiceUnvoided: Invoice unvoided
  noSoldToContact: No billing contact set

# used for buttons and menu items
# note that "<action>Model" keys should be used with models keys
actions:
  createModel: Create {model}
  edit: Edit
  editDetails: Edit Details
  delete: Delete
  removeLink: Remove Link
  search: Search
  confirm: Confirm
  save: Save
  saveCustom: Save as Custom
  saveEdits: Save Edits
  send: Send
  sendInvoice: Send Email
  resend: Resend
  cancel: Cancel
  back: Back
  archive: Archive
  unarchive: Unarchive
  duplicate: Duplicate Template
  useTemplate: Use this Template
  view: View
  payments: Payments
  addPayment: Add Payment
  addPartialPayment: Add Partial Payment
  payOutstanding: Pay Outstanding
  notes: Notes
  addNote: Add a note
  default: Set as Default
  signin: Sign in
  signout: Sign out
  signoutConfirm: Yes, sign me out
  downloadPdf: Download PDF
  pay: Pay via Credit Card
  payOverTime: Pay over time
  payViaAch: Pay via ACH
  copy: Copy
  savePassword: Save Password
  newPasscode: New Passcode
  inventoryCountTotal: Exclude artwork items from the invoice total amount.
  inventoryCountTotalSub: (Does not affect custom line items)
  markAsSold: Mark {number} {item} above as SOLD
  markedAsSold: Marked {number} {item} as sold
  selectFromInventory: Select From Inventory
  selectFromInventoryPlaceholder: Search inventory for artwork
  viewArtwork: View artwork
  selectFromContactsPlaceholder: Search for contacts
  selectFromAddressesPlaceholder: Search for addresses
  selectFromAddresses: Select from addresses
  selectPaymentInstructions: Choose Payment Instructions
  selectTermsAndConditions: Choose Terms and Conditions
  select: Select
  createLineItem: Add Custom Line Item
  createAutoCCFeeLineItem: Credit Card Transaction Fee
  applyThisTaxRate: Apply this Rate
  remove: Remove
  removeDiscount: Remove Discount
  addDiscount: Add Discount
  removeTax: Remove Tax
  addTax: Add Tax
  addShippingTax: Add Shipping Tax
  addTerms: Add Terms and Conditions
  addPaymentInstructions: Add Payment Instructions
  addDetails: Add Details
  previewInvoice: Preview Invoice
  viewInvoice: View Invoice
  previous: Previous
  prev: Prev
  next: Next
  customizeTemplate: Customize Template
  placeholders: Placeholders
  customDetail: Custom Detail
  syncToQuickbooks: Sync to Quickbooks
  customizeLabels: Customize Labels
  autoTax: Apply {percent} tax 
  clearTax: Clear Tax
  manageTaxRates: Manage tax rates
  searchContact: Search for Contact
  clearFilters: Clear Filters
  addRelatedContact: Add related contact
  yes: Yes
  no: No
  editAmount: Edit Amount
  editPercentage: Edit Percentage
  resetFees: Reset Fees
  enterLabel: Enter label
  viewDashboard: View Dashboard
  relinkEmailAccount: Relink Your Email Account
  crop: Crop
  continue: Continue
  addLabel: Add Label
  addLabels: Add Labels
  void: Void
  voidInvoice: Void Invoice
  unvoid: Unvoid
  changeCurrency: Change Currency
  enterTaxAmount: Enter Tax Amount
  enterTaxRate: Enter Tax Rate

# form labels should have no suffix (eg: "email")
# placeholders should have "<field>Placeholder" suffix
# help text should have "<field>Help" suffix
forms:
  signin:
    email: Email address
    emailPlaceholder: Email address
    password: Password
    passwordPlaceholder: Password
    showPassword: Show Password
  passcode:
    protect: Protect with a passcode
    passcodePlaceholder: Enter Passcode
    showPasscode: Show Passcode
  contact:
    attn: ATTN
    honorific: Honorific
    firstName: First name
    lastName: Last name
    email: Email address
    phoneHome: Phone (home)
    phoneOffice: Phone (office)
    phoneMobile: Phone (mobile)
    phoneOther: Phone (other)
  address:
    line1: Address
    line2: Address Line 2
    line1Placeholder: Street address
    line2Placeholder: Unit number, etc
    locality: City
    region: Region
    country: Country
    postalCode: Zip/Postal Code
  saleAddress:
    name: Full Name/Address Name
  lineItem:
    description: Description
    price: Price ({currency})
    applyToAllLineItems: Apply to all Line Items
  invoiceEdit:
    title: 'Invoice Title (optional)'
    invoiceNumber: 'Invoice Number'
    date: 'Date'
    dueDate: 'Due Date'
    createdBy: 'Created By'
    taxAmount: 'Tax Amount'
    taxRate: '{percent}'
    taxRatePlaceholder: Enter tax rate
    presetTaxRate: '{percent} - {key}'
    taxRateDropdown: Tax Rate Dropdown
    isLineItemTaxed: Taxed
    termsAndPaymentHeader: Terms and Payment Instructions
    terms: Terms and Conditions
    paymentInstructions: Payment Instructions
    newTerms: New Terms and Conditions
    newPaymentInstructions: New Payment Instructions
    labels: Labels
    disableArtworkPrices: Artwork prices excluded from the total
    enableArtworkPrices: Artwork prices are included in the total
  physicalSale:
    markAsPhysicalSale: This is an in-person sale
    physicalSaleTooltip: >-
      For tax calculation purposes, if this sale was made at an out of state location,
      an event or fair.
    physicalSaleHelp: (This will hide the shipping details in the invoice)
  send:
    to: To
    toHelp: Press enter to add
    toPlaceholder: Type to search
    cc: Cc
    ccHelp: Press enter to add
    ccPlaceholder: Type to search
    bcc: Bcc
    bccHelp: Press enter to add
    bccPlaceholder: Type to search
    subject: Subject
    message: Message
    noToEmail: The To field cannot be empty
    invalidEmail: There is at least one invalid email
    linkColor: Link Color
  termsAndConditions:
    notesPlaceholder: Enter terms and conditions to be included in your invoice.
  paymentInstructions:
    notesPlaceholder: Enter details regarding any payment instructions. E.g. Bank Wire, Cheque, Credit Card.
  templateSettings:
    header: Header
    headerAlignment: Header alignment
    headerSize: Header size
    showCurrencyCode: Show currency codes
    showCurrencySymbol: Show currency symbols
    showPayments: Show payments & remaining balance
    showDiscountTotal: Show discount total
    additionalDetails: Additional details
    additionalDetailsOwnPage: Move additional details onto a separate page
    itemCaptionFormat: Item caption format
    additionalHeading: Additional heading text
    invoiceNumber: Invoice number label
    date: Date label
    dueDate: Due Date label
    billingAddress: Billing address label
    shippingAddress: Shipping address label
    subtotal: Subtotal label
    tax: Tax label
    shipping: Shipping label
    total: Total label
    remainingBalance: Remaining balance label
    footer: Footer
    footerAlignment: Footer alignment
    left: Left
    center: Center
    right: Right
    small: Small
    medium: Medium
    large: Large
    font: Font
    transactionFee: Transaction fee label
    defaultCurrency: Default currency
    defaultCurrencyDefault: Use organization default
    hideLineItemTax: Hide sales tax on line items
    useFullDate: Use full date formatting
    showDueDate: Show due date
  detailPreset:
    name: Name
    namePlaceholder: Preset name
    content: Content
    contentPlaceholder: Preset content
    autoInclude: Automatically include this detail in every invoice
    signatureBlock: Signature Block
    signatureDefault: Signature
  contactSearch:
    searchPlaceholder: Search for Contacts
    noResults: No contacts found.
  userSearch:
    searchPlaceholder: Search for Users
  quickbooksSync:
    title: Select a Quickbooks tax agency
    agency: Tax agency
    agencyPlaceholder: Select tax agency
    agencyHelp: Don't see your agency?
    agencyLink: Find out how to add it
    shippingTo: Shipping To
  invoices:
    search: Search Invoices
    searchPlaceholder: Search Invoices
  paymentMethods:
    title: ARTERNAL Pay Options
    helper: Selected payment options will be available on this invoice.
    disabledPayment: The total invoice amount must be in USD or CAD and under $30,000 for Affirm.
    achDisabled: 'ACH is only available in USD'
    negativeItems: 'All line items must be greater than $0.00.'
    paymentEnabled: payment enabled
    fundsDeposited: Funds will be deposited instantly in your bank account
    feeBreakdown: Fee Breakdown
    transactionFee: Transaction fee
    totalPayout: Total Payout
    readReviewTerms: Read and review terms of service
    additionalFees: 🌎 International credit cards and currencies are subject to additional charges.
  voidReason:
    title: Void Invoice
    placeholder: Enter reason

components:
  fileDrop:
    browse: Click to browse or drop files here
  nexusDisplay:
    sales: Sales
    salesResult: '{amount} of {total}'
    transactions: Transactions
    transactionsResult: '{amount} of {total}'
    lookback: Lookback Period
    na: Not applicable
    nexusApproaching: Economic nexus not yet reached
    nexusReached: ✅ You've reached 100% of the nexus requirements. You are responsible for registering and collecting sales tax in the state
    nexusDescription: Economic Nexus determines whether sales tax is required for the shipping state.
    posNotInUS: The point of sale must be within a US state to view economic nexus
  search:
    location: Location
    sortBy: Sort by
    status: Status
    stage: Stage
    labels: Labels
    newestDate: Invoice Date (Newest)
    oldestDate: Invoice Date (Oldest)
    newestUpdated: Updated At (Newest)
    oldestUpdated: Updated At (Oldest)
    newestDue: Due Date (Newest)
    oldestDue: Due Date (Oldest)
    all: All
  taxRateFilter:
    noTaxRatesFound: No tax rates found
    addTaxRate: Add tax rate
  newTaxRateModal:
    title: New Tax Rate
  imgCropper:
    title: Crop Image
    helpText: Use the handles below to obtain your desired cropped image
  tagDropdown:
    noLabelsFound: No labels found
  tagFilter:
    createLabel: "Create new label: \"{name}\""

# page-specific headings and copy only
# make sure keys that belong in actions or messages do not go here
pages:
  signin:
    title: Sign in
  signout:
    title: Sign out
    confirm: Are you sure you want to sign out?
  callback:
    title: Signing In
  account:
    title: Your Account
  invoices:
    title: Invoices
  invoiceCreate:
    title: Create an Invoice
  invoiceEdit:
    title: Edit
    back: Back to Invoice
    billTo: Bill To
    billingAddress: Billing Address
    shipTo: Ship To
    shippingAddress: Shipping Address
    shipToBilling:  Shipping address is the same as billing
    salesAssociate: Sales Associate
    artistAssociate: Artist Associate
    lineItems: Items
    physicalSale: In-Person Sale
    shipping: Shipping
    deposit: Deposit
    currency: Currency
    taxAmount: Tax Amount
    taxRate: Tax Rate
    economicNexus: Economic Nexus
    transactionFees: Transaction Fees
  invoiceView:
    title: Preview
  invoiceSend:
    title: Send
  invoicePayments:
    title: Payments
  invoiceSentHistory:
    title: Sent History
    sentBy: Sent by {fullName} {date} {time}
    noHistory: No history found
    unknownContact: Unknown contact
  invoiceNotes:
    title: Notes
  invoiceSettings:
    title: Invoice Settings
    detailPresets: Detail Presets
  invoiceVerify:
    title: View Your Invoice
  templates:
    title: Templates
    custom: Custom Templates
    predefined: Predefined Templates
  templateEdit:
    title: Custom Template ({name})
    predefinedTitle: Predefined Template ({name})

# invoice status
invoiceStatus:
  archived: Archived
  draft: Unsent
  sent: Sent
  viewed: Viewed
  views: '{count} Views'
  paid: Paid
  partial: Partially Paid
  unpaid: Unpaid
  errorSending: Error Sending
  void: Void
  voided: Voided

fontSize:
  regular: Regular font size
  small: Small font size
  smallest: Smallest font size

payments:
  payOutstanding: Pay Outstanding
  paymentAmount: Payment Amount
  outstandingBalance: Outstanding balance
  helpText: Enter a negative amount for a bounced or cancelled payment
  date: Date
  datePlaceHolder: Enter invoice date
  viewPayables: View Payables
  payablesCreated: Payables Created
  canCreatePayables: You can automatically create payables for this invoice
  autoCreatePayables: Auto-create payables
  confirmCreatePayables: Are you sure you want to create payables from the active consignment terms?

sidebar:
  home: Home
  mail: Mail
  contacts: Contacts
  inventory: Inventory
  transactions: Transact
  reports: Reports
  invoices: Invoices
  deals: Deals
  payments: Payments
  settings: Settings
  viewPayables: View Payables
  payablesCreated: Payables Created
  canCreatePayables: You can automatically create payables for this invoice
  autoCreatePayables: Auto-create payables
  confirmCreatePayables: Are you sure you want to create payables from the active consignment terms?
